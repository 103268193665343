
import { Component, Inject, Vue } from 'vue-property-decorator';
import Dropdown from '@/components/parts/input/dropdown.vue';
import GlobalState from '@/state/GlobalState';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import ProductData from '@/repository/data/ProductData';
import ProductCategoryData from '@/repository/data/ProductCategoryData';
import Page from '@/repository/Page';
import { Sort, SortDirection } from '@/repository/data/Pageable';
import WebShopRepository from '@/repository/WebShopRepository';
import Pagination from '@/components/parts/Pagination.vue';
import ShopHeaderData from '@/repository/data/ShopHeader';
import LoadingSpinner from '@/components/parts/LoadingSpinner.vue';
import User from '@/repository/data/User';
import MultiSourceVideoRenderer from '@/components/parts/MultiSourceVideoRenderer.vue';
import PageMediaHeader from '@/components/parts/PageMediaHeader.vue';

@Component({
    components: { PageMediaHeader, MultiSourceVideoRenderer, LoadingSpinner, Dropdown, Pagination },
    filters: {
        EUR(value: number): string {
            return `${Math.floor(value / 100)},${value % 100 < 10 ? '0' + (value % 100) : value % 100}`;
        },
    },
})
export default class ShopHome extends Vue {
    public readonly sortOptions: {
        name: object | string;
        id: number;
        sort: { property: string; direction: SortDirection };
    }[] = [];
    @Inject() private readonly webShopRepository!: WebShopRepository;
    @Inject() private readonly globalState!: GlobalState;
    private productsSubscription = new Subscription();
    private pageSubscription = new Subscription();
    private languageSubscription = new Subscription();
    private categorySubscription = new Subscription();
    private headerSubscription = new Subscription();
    private userSubscription = new Subscription();
    public error?: any | null;
    public categories?: ProductCategoryData[] = [];
    private language = new BehaviorSubject('');
    private pageable = new BehaviorSubject({ page: 0, size: 0, sort: [] as Sort[] });
    public loading = true;
    public loadingProducts = true;
    public productPage?: Page<ProductData> | null = null;
    public page = new BehaviorSubject(1);
    public pageSize = new BehaviorSubject(6);
    public sort: BehaviorSubject<Sort[]> = new BehaviorSubject([] as Sort[]);
    private category: BehaviorSubject<number> = new BehaviorSubject(-1);
    private user: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(null);
    public header: ShopHeaderData | null = null;
    private showOrganisation = new BehaviorSubject(false);

    public created(): void {
        this.sortOptions.push({
            name: this.$t('sort.priceDesc'),
            id: 0,
            sort: { property: 'price', direction: SortDirection.DESC },
        });
        this.sortOptions.push({
            name: this.$t('sort.priceAsc'),
            id: 1,
            sort: { property: 'price', direction: SortDirection.ASC },
        });
        this.languageSubscription = this.globalState.languageObservable.subscribe(this.language);
        this.categorySubscription = this.language.subscribe((lang) => {
            this.webShopRepository.getCategories(lang).subscribe((categoryResource) => {
                this.loading = categoryResource.isLoading;
                if (categoryResource.isSuccess && categoryResource.data !== null) {
                    this.categories = categoryResource.data;
                    this.categories.push({ name: this.$t('filter.everything') as string, id: -1 });
                } else if (categoryResource.isError) {
                    this.error = categoryResource.error.response;
                }
            });

            this.webShopRepository.getHeader(lang).subscribe((headerResource) => {
                if (headerResource.isSuccess && headerResource.data !== null) {
                    this.header = headerResource.data;
                } else if (headerResource.isError) {
                    this.error = headerResource.error.response;
                }
            });
        });
        this.userSubscription = this.globalState.userObservable.subscribe((userResource) => {
            if (userResource.isSuccess && userResource.data !== null) {
                this.user.next(userResource.data);
            } else if (userResource.isError) {
                this.error = userResource.error.response;
            }
        });

        this.pageSubscription = combineLatest([this.page, this.pageSize, this.sort]).subscribe(([page, size, sort]) => {
            this.pageable.next({ page: page - 1, size, sort });
        });

        this.productsSubscription = combineLatest([
            this.pageable,
            this.language,
            this.category,
            this.showOrganisation,
        ]).subscribe(([pageable, language, category, organisation]) => {
            if (organisation) {
                this.webShopRepository
                    .findOrganisationProductPage(language, pageable, category === -1 ? null : category)
                    .subscribe((resource) => {
                        this.loadingProducts = resource.isLoading;
                        if (resource.isSuccess && resource.data != null) {
                            this.productPage = resource.data;
                            this.loadingProducts = false;
                        } else if (resource.isError) {
                            this.error = resource.error.response;
                        }
                    });
            } else {
                this.webShopRepository
                    .findProductPage(language, pageable, category === -1 ? null : category)
                    .subscribe((resource) => {
                        this.loadingProducts = resource.isLoading;
                        if (resource.isSuccess && resource.data != null) {
                            this.productPage = resource.data;
                            this.loadingProducts = false;
                        } else if (resource.isError) {
                            this.error = resource.error.response;
                        }
                    });
            }
        });
        this.loading = false;
        // Hide dropdown elements in case they are open
        document.addEventListener('click', (event: any) => {
            let dropdownElements = document.getElementsByClassName('dropdown');
            for (let el of dropdownElements) {
                el.classList.remove('is-active');
            }
            if (event.target.closest('.dropdown')) {
                event.target.closest('.dropdown').classList.add('is-active');
            }
        });
    }

    public beforeDestroy(): void {
        this.productsSubscription.unsubscribe();
        this.pageSubscription.unsubscribe();
        this.languageSubscription.unsubscribe();
        this.categorySubscription.unsubscribe();
        this.headerSubscription.unsubscribe();
        this.userSubscription.unsubscribe();
    }

    public filterCategory(val: string) {
        switch (val.split(':')[0]) {
            case 'categories':
                this.category.next(+val.split(':')[1]);
                break;
            case 'sort':
                for (let option of this.sortOptions) {
                    if (option.id === +val.split(':')[1]) {
                        this.sort.next([option.sort]);
                    }
                }
                break;
        }
    }

    public onPageSelected(page: number) {
        this.page.next(page);
    }

    public get totalPages(): number {
        return this.productPage && this.productPage.pageable
            ? Math.ceil(this.productPage.total / this.productPage.pageable.size)
            : 0;
    }

    public get currentPageIndex(): number {
        return this.productPage && this.productPage.pageable ? this.productPage.pageable.page + 1 : 1;
    }

    public get isOrganisation(): boolean {
        let user: User | null = this.user.value;
        if (user !== null) {
            for (let org of user.organizations) {
                if (org.shopper) {
                    return true;
                }
            }
        }
        return false;
    }

    public toggleOrganisation() {
        this.showOrganisation.next(!this.showOrganisation.value);
    }
}
