
import { Component, Inject, Vue, Watch } from 'vue-property-decorator';
import LoadingSpinner from '@/components/parts/LoadingSpinner.vue';
import PublicationsRepository from '@/repository/PublicationsRepository';
import Page from '@/repository/Page';
import Publication from '@/repository/data/Publication';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import GlobalState from '@/state/GlobalState';
import Pagination from '@/components/parts/Pagination.vue';
import { SortDirection } from '@/repository/data/Pageable';
import MultiSourceVideoRenderer from '@/components/parts/MultiSourceVideoRenderer.vue';

@Component({
    components: { LoadingSpinner, Pagination, MultiSourceVideoRenderer },
})
export default class Publications extends Vue {
    public search: string | null = null;
    public loading = true;

    @Inject() public globalState!: GlobalState;
    @Inject() private publicationsRepository!: PublicationsRepository;
    private languageSubject = new BehaviorSubject<string>('nl-NL');
    private pageSubject = new BehaviorSubject<number>(1);
    private searchSubject = new BehaviorSubject<string | null>(null);
    private languageSubscription = new Subscription();
    private publicationsSubscription = new Subscription();
    private publicationsPage: Page<Publication> = {
        total: 0,
        content: [],
        pageable: {
            size: 10,
            page: 0,
            sort: [],
        },
    };

    public created(): void {
        this.languageSubscription = this.globalState.languageObservable.subscribe(this.languageSubject);
        this.publicationsSubscription = combineLatest([
            this.languageSubject,
            this.pageSubject,
            this.searchSubject.pipe(debounceTime(300)),
        ])
            .pipe(
                switchMap(([language, page, search]) =>
                    this.publicationsRepository.findPublicationPage(
                        language,
                        { page: page - 1, size: 10, sort: [{ property: 'year', direction: SortDirection.DESC }] },
                        search,
                    ),
                ),
            )
            .subscribe((publicationsPageResource) => {
                this.loading = publicationsPageResource.isLoading;
                if (publicationsPageResource.isSuccess && publicationsPageResource.data) {
                    this.publicationsPage = publicationsPageResource.data;
                }
                // TODO: we need clean error handling
            });
    }

    public beforeDestroy(): void {
        this.languageSubscription.unsubscribe();
        this.publicationsSubscription.unsubscribe();
    }

    @Watch('search')
    public onSearchChanged(search: string | null) {
        this.searchSubject.next(search);
    }

    public onPageSelected(page: number) {
        this.pageSubject.next(page);
    }

    public get publications(): Publication[] {
        return this.publicationsPage.content;
    }

    public get totalPages(): number {
        return Math.ceil(this.publicationsPage.total / this.publicationsPage.pageable.size);
    }

    public get currentPageIndex(): number {
        return this.publicationsPage.pageable.page + 1;
    }
}
