
import { Component, Inject, Vue } from 'vue-property-decorator';
import GlobalState from '@/state/GlobalState';
import PageRepository from '@/repository/PageRepository';
import marked from 'marked';
import { Subscription } from 'rxjs';
import FooterData from '@/repository/data/FooterData';
import FooterLinkData from '@/repository/data/FooterLinkData';

@Component
export default class AppFooter extends Vue {
    @Inject() private readonly globalState!: GlobalState;
    @Inject() private readonly pageRepository!: PageRepository;
    private languageSubscription = new Subscription();
    private footerSubscription = new Subscription();
    private footer: FooterData | null = null;

    public created(): void {
        this.languageSubscription = this.globalState.languageObservable.subscribe((lang) => {
            this.pageRepository.getFooter(lang).subscribe((resource) => {
                if (resource.isSuccess && resource.data) {
                    this.footer = resource.data;
                }
            });
        });
    }

    public beforeDestroy(): void {
        this.languageSubscription.unsubscribe();
        this.footerSubscription.unsubscribe();
    }

    public parseMarkdown(markdown: string): string {
        return marked(markdown);
    }

    public get firstHalfLinks(): FooterLinkData[] {
        if (this.footer) {
            return this.footer.links.slice(0, this.footer.links.length / 2);
        } else {
            return [];
        }
    }

    public get secondHalfLinks(): FooterLinkData[] {
        if (this.footer) {
            return this.footer.links.slice(this.footer.links.length / 2);
        } else {
            return [];
        }
    }
}
