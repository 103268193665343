
import { Component, Prop, Vue } from 'vue-property-decorator';
import GlobalState from '@/state/GlobalState';
import NavigationItem from '@/repository/data/NavigationItem';

@Component
export default class NavigationItemRenderer extends Vue {
    @Prop({ type: Object }) public readonly navItem!: NavigationItem;
    @Prop({ type: Object }) public readonly globalState!: GlobalState;
    public expanded = false;

    public toggleNavigationItem(): void {
        const listParent: any = this.$refs.listParent;
        const nextExpandedState = !this.expanded;
        if (listParent) {
            if (nextExpandedState) {
                this.expanded = nextExpandedState;
                this.$nextTick().then(() => {
                    listParent.style.maxHeight = listParent.scrollHeight + 'px';
                });
            } else {
                listParent.style.maxHeight = null;
                setTimeout(() => {
                    this.expanded = nextExpandedState;
                }, 200);
            }
        }
    }

    public closeNavigationItem(): void {
        if (this.expanded) {
            this.toggleNavigationItem();
        }
    }
}
