
import { Component, Inject, Vue } from 'vue-property-decorator';
import { Subscription } from 'rxjs';
import AuthManager from '@/util/auth/AuthManager';
import GlobalState from '@/state/GlobalState';

@Component
export default class LoginScreen extends Vue {
    public loading: boolean = true;
    public error = false;

    private authSubscription = new Subscription();
    @Inject() private readonly authManager!: AuthManager;
    @Inject() private readonly globalState!: GlobalState;

    public created(): void {
        if (this.$route.query.hasOwnProperty('code') && this.$route.query.hasOwnProperty('state')) {
            this.authSubscription = this.authManager
                .validateLogin(this.$route.query.code as string, this.$route.query.state as string)
                .subscribe((tokenResource) => {
                    this.loading = tokenResource.isLoading;
                    if (tokenResource.isSuccess) {
                        this.globalState.refreshUser();
                        const rememberedRoute = window.localStorage.getItem('rememberedRoute');
                        window.localStorage.removeItem('rememberedRoute');
                        if (window.localStorage.getItem('loginFromCart')) {
                            window.localStorage.removeItem('loginFromCart');
                            this.$router.push({ name: 'checkout' });
                        } else if (rememberedRoute) {
                            this.$router.push(JSON.parse(rememberedRoute));
                        } else {
                            this.$router.push({ name: 'home' });
                        }
                    } else if (tokenResource.isError) {
                        this.error = true;
                    }
                });
        } else {
            this.globalState.refreshUser();
            this.$router.push({ name: 'home' });
        }
    }

    public beforeDestroy(): void {
        this.authSubscription.unsubscribe();
    }
}
