import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import ShoppingCart from '@/state/ShoppingCart';
import WebShopRepository from '@/repository/WebShopRepository';
import AuthManager from '@/util/auth/AuthManager';
import User from '@/repository/data/User';
import UserRepository from '@/repository/UserRepository';
import Resource from '@/repository/Resource';

export default class GlobalState {
    private readonly _authManager: AuthManager;
    private readonly _userRepository: UserRepository;
    private _userSubscription = new Subscription();
    private readonly _languageSubject: BehaviorSubject<string>;
    private readonly _cartSizeSubject: BehaviorSubject<number>;
    private readonly languageSubscription: Subscription;
    private readonly _cart: ShoppingCart;
    private _userSubject = new BehaviorSubject<Resource<User>>(Resource.loading<User>(null));

    constructor(authManager: AuthManager, userRepository: UserRepository, webShopRepository: WebShopRepository) {
        this._authManager = authManager;
        this._userRepository = userRepository;
        this._cartSizeSubject = new BehaviorSubject<number>(0);
        localStorage.language = localStorage.language || 'nl-NL';
        this._languageSubject = new BehaviorSubject<string>(localStorage.language);
        this.languageSubscription = this._languageSubject.subscribe((language) => (localStorage.language = language));
        this._cart = new ShoppingCart(this, webShopRepository);
    }

    public beginLogin(): void {
        this._authManager.startRedirectLogin();
    }

    public beginLogout(): void {
        this._authManager.startRedirectLogout();
    }

    public refreshUser(): void {
        this._userSubscription.unsubscribe();
        this._userSubscription = this._userRepository
            .findCurrent()
            .subscribe((resource) => this._userSubject.next(resource));
    }

    public get userObservable(): Observable<Resource<User>> {
        return this._userSubject.asObservable();
    }

    public get languageObservable(): Observable<string> {
        return this._languageSubject.asObservable();
    }

    public setLanguage(value: string) {
        if (this._languageSubject.value !== value) {
            this._languageSubject.next(value);
        }
    }

    public get language() {
        return this._languageSubject.getValue();
    }

    public get cart() {
        return this._cart;
    }

    public getFileUrl(fileId: number) {
        if (fileId === undefined) return '';
        return process.env.VUE_APP_NGC_API_ENDPOINT + '/file/' + fileId + '/download';
    }

    public getThumbUrl(fileId: number) {
        if (fileId === undefined) return '';
        return process.env.VUE_APP_NGC_API_ENDPOINT + '/file/' + fileId + '/thumb';
    }

    public setCartSize(amount: number) {
        this._cartSizeSubject.next(amount);
    }

    public get cartSizeObservable(): Observable<number> {
        return this._cartSizeSubject.asObservable();
    }
}
