
import { Component, Prop, Vue } from 'vue-property-decorator';
import MultiSourceVideoRenderer from '@/components/parts/MultiSourceVideoRenderer.vue';
import MultiSourceVideo from '@/repository/data/MultiSourceVideo';

@Component({
    components: {
        MultiSourceVideoRenderer,
    },
})
export default class PageMediaHeader extends Vue {
    @Prop({ required: false, type: String, default: '' })
    public readonly title!: string;

    @Prop({ required: false, type: String, default: '' })
    public readonly titleLead!: string;

    @Prop({ required: false, type: String, default: '' })
    public readonly description!: string;

    @Prop({ required: false, type: Boolean, default: false })
    public readonly stripVideoFramePadding!: boolean;

    @Prop({ type: Object, required: false, default: null })
    public mediaSource!: MultiSourceVideo | null;

    @Prop({ required: false, type: Boolean, default: false })
    public readonly complementary!: boolean;

    public get videoFrameClasses(): { [key: string]: boolean } {
        return {
            'is-paddingless-desktop': this.stripVideoFramePadding,
        };
    }
}
