
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class NumberFieldWithDropdown extends Vue {
    @Prop({ type: Number, default: 0 }) private readonly amountCap!: number;
    @Prop({ type: Number, default: 0 }) private value!: number;

    private manual = false;
    private expanded: boolean = false;
    private innerValue: number = 0;

    public get options(): number[] {
        return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].filter((t) => t <= this.amountCap);
    }

    @Watch('value', { immediate: true })
    public onValueChanged() {
        if (this.value !== this.innerValue) {
            this.innerValue = this.value;
        }
    }

    @Watch('innerValue')
    public onInnerValueChanged(): void {
        if (this.innerValue > this.amountCap) {
            this.innerValue = this.amountCap;
        } else if (this.innerValue < 1) {
            this.innerValue = 1;
        }
        this.$emit('input', +this.innerValue);
    }

    public onMore() {
        this.expanded = false;
        this.manual = true;
    }

    public onClickIcon() {
        const displayInput: any = this.$refs.displayInput;
        if (displayInput) {
            displayInput.focus();
        }
    }

    public selectValue(selected: number) {
        this.innerValue = selected;
        this.expanded = false;
    }
}
