
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Pagination extends Vue {
    @Prop({ type: Number, default: 1 }) public readonly totalPageCount!: number;
    @Prop({ type: Number, default: 1 }) public readonly currentPage!: number;

    public get paginationPrevious(): number {
        return Math.max(this.currentPage - 1, 1);
    }

    public get paginationNext(): number {
        return Math.min(this.currentPage + 1, this.totalPageCount);
    }

    public get paginationElements(): number[] {
        const pages: number[] = [];
        const pagesWithSpacers: number[] = [];
        const last = this.totalPageCount;
        const current = this.currentPage;
        const delta = 2;
        const left = current - delta;
        const right = current + delta + 1;

        for (let i = 1; i <= last; i++) {
            if (i === 1 || i === last || (i >= left && i < right)) {
                pages.push(i);
            }
        }

        let l = 0;
        let spacerId = -1;
        for (const i of pages) {
            if (l) {
                if (i - l === 2) {
                    pagesWithSpacers.push(l + 1);
                } else if (i - l !== 1) {
                    pagesWithSpacers.push(spacerId);
                    spacerId--;
                }
            }
            pagesWithSpacers.push(i);
            l = i;
        }

        return pagesWithSpacers;
    }

    public onPageSelected(page: number) {
        this.$emit('pageSelected', page);
    }
}
