import Vue from 'vue';
import App from './components/App.vue';
import router from './router';
import i18n from './i18n';
// @ts-ignore
import { Autocomplete, Modal } from 'buefy';

Vue.config.productionTip = false;
Vue.use(Autocomplete);
Vue.use(Modal);

new Vue({
    router,
    i18n,
    render: (h) => h(App),
}).$mount('#app');
