
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Dropdown extends Vue {
    @Prop({ type: Array, default: [] }) private readonly classes!: [];
    @Prop({ type: String, default: '' }) private readonly label!: string;
    @Prop({ type: Array, default: [] }) private readonly options!: [];
    @Prop({ type: String, default: '' }) private readonly paramType!: string;

    public itemSelected(key: string): void {
        this.$emit('filterCategory', this.paramType + ':' + key);
        this.$el.classList.remove('is-active');
    }
}
