
import { Component, Inject, Vue, Watch } from 'vue-property-decorator';
import Carousel from '@/components/parts/Carousel.vue';
import ProductData from '@/repository/data/ProductData';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import GlobalState from '@/state/GlobalState';
import WebShopRepository from '@/repository/WebShopRepository';
import LoadingSpinner from '@/components/parts/LoadingSpinner.vue';
import NumberFieldWithDropdown from '@/components/parts/input/NumberFieldWithDropdown.vue';

@Component({
    components: { NumberFieldWithDropdown, LoadingSpinner, Carousel },
    filters: {
        EUR(value: number): string {
            return `${Math.floor(value / 100)},${value % 100 < 10 ? '0' + (value % 100) : value % 100}`;
        },
    },
})
export default class Product extends Vue {
    @Inject() private readonly webShopRepository!: WebShopRepository;
    @Inject() private readonly globalState!: GlobalState;
    private productSubscription = new Subscription();
    private languageSubscription = new Subscription();
    public product?: ProductData | null;
    public recommendedProducts?: ProductData[] = [];
    public error?: any | null;
    public amount = 1;
    public modalActive = false;
    private loading = true;
    private productId = new BehaviorSubject('');
    private language = new BehaviorSubject('');
    private carouselImages = new BehaviorSubject<{ id: number; big: string; thumb: string }[]>([]);

    public created(): void {
        this.product = null;
        this.languageSubscription = this.globalState.languageObservable.subscribe(this.language);
        this.productSubscription = combineLatest([this.productId, this.language]).subscribe((obj) => {
            this.webShopRepository.getProduct(obj[1], +obj[0]).subscribe((productResource) => {
                this.loading = productResource.isLoading;
                if (productResource.isSuccess && productResource.data !== null) {
                    this.product = productResource.data;
                    let imageArray: Array<{ id: number; big: string; thumb: string }> = [];
                    let id = 0;
                    for (let image of this.product.images) {
                        imageArray.push({
                            id: id++,
                            big: this.globalState.getFileUrl(image),
                            thumb: this.globalState.getFileUrl(image),
                        });
                    }
                    this.carouselImages.next(imageArray);
                    this.loading = false;
                } else if (productResource.isError) {
                    this.error = productResource.error.response;
                    this.loading = false;
                }
            });

            this.webShopRepository.getRecommendedProducts(obj[1], +obj[0]).subscribe((recommended) => {
                if (recommended.isSuccess && recommended.data != null) {
                    this.recommendedProducts = recommended.data;
                }
            });
        });
    }

    public beforeDestroy(): void {
        this.languageSubscription.unsubscribe();
        this.productSubscription.unsubscribe();
    }

    public toggleDescription(): void {
        let desc = document.getElementById('product-description');
        if (desc) {
            desc.classList.toggle('is-expanded');
            if (desc.classList.contains('is-expanded')) {
                desc.setAttribute('style', 'max-height: ' + desc.scrollHeight + 'px !important');
            } else {
                desc.style.maxHeight = '0';
            }
        }
    }

    public addToCart(): void {
        if (this.product && this.product.digital && !this.product.organisationOnly) {
            this.globalState.cart.set(+this.productId.value, 1);
        } else {
            this.globalState.cart.add(+this.productId.value, this.amount);
        }
        this.modalActive = true;
    }

    public get amountCap(): number {
        if (this.product) {
            if (this.product.digital) {
                return 1;
            } else if (this.product.backOrder) {
                return 0;
            }
            return this.product.stock;
        }
        return 0;
    }

    @Watch('$route', { immediate: true })
    onUrlChange(to: any, from: any) {
        if (from === undefined || to.path !== from.path) {
            this.productId.next(this.$route.params.product);
        }
    }

    public goToCard() {
        this.$router.push('/shop/basket');
    }

    public goToShop() {
        this.$router.push('/shop');
    }
}
