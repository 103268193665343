
import { Component, Inject, Vue } from 'vue-property-decorator';
import PageRepository from '@/repository/PageRepository';
import PinnedPageData from '@/repository/data/PinnedPageData';
import WeeklySignData from '@/repository/data/WeeklySignData';
import { combineLatest, Subscription } from 'rxjs';
import DirectNavigationData from '@/repository/data/DirectNavigationData';
import GlobalState from '@/state/GlobalState';
import { switchMap } from 'rxjs/operators';
import LoadingSpinner from '@/components/parts/LoadingSpinner.vue';
import SubPages from '@/components/parts/content/SubPages.vue';
import MultiSourceVideo from '@/repository/data/MultiSourceVideo';
import PageMediaHeader from '@/components/parts/PageMediaHeader.vue';
import FrontPageContent from '@/repository/data/FrontPageContent';

@Component({
    components: {
        PageMediaHeader,
        SubPages,
        LoadingSpinner,
    },
})
export default class Home extends Vue {
    @Inject() private readonly pageRepository!: PageRepository;
    @Inject() private readonly globalState!: GlobalState;
    private pinnedPages: PinnedPageData[] = [];
    private frontPageContent: FrontPageContent = {
        frontPageFrameText: '',
    };
    private directNavigations: DirectNavigationData[] = [];
    private weeklySign: WeeklySignData | null = null;
    private contentSubscription = new Subscription();
    private loading = true;

    public created(): void {
        this.frontPageContent = {
            frontPageFrameText: this.$t('home.directToHeader').toString(),
        };
        this.contentSubscription = this.globalState.languageObservable
            .pipe(
                switchMap((language) => {
                    return combineLatest([
                        this.pageRepository.getPinnedPages(language),
                        this.pageRepository.getWeeklySign(language),
                        this.pageRepository.getFrontPageContent(language),
                        this.pageRepository.getDirectNavigation(language),
                    ]);
                }),
            )
            .subscribe(
                ([pinnedPagesResource, weeklySignResource, frontPageContentResource, directNavigationResource]) => {
                    this.loading =
                        pinnedPagesResource.isLoading ||
                        weeklySignResource.isLoading ||
                        frontPageContentResource.isLoading ||
                        directNavigationResource.isLoading;
                    if (pinnedPagesResource.isSuccess && pinnedPagesResource.data !== null) {
                        this.pinnedPages = pinnedPagesResource.data;
                    }
                    if (weeklySignResource.isSuccess && weeklySignResource.data !== null) {
                        this.weeklySign = weeklySignResource.data;
                    }
                    if (frontPageContentResource.isSuccess && frontPageContentResource.data !== null) {
                        this.frontPageContent = frontPageContentResource.data;
                    }
                    if (directNavigationResource.isSuccess && directNavigationResource.data !== null) {
                        this.directNavigations = directNavigationResource.data;
                    }
                },
            );
    }

    public beforeDestroy(): void {
        this.contentSubscription.unsubscribe();
    }

    public get renderedFrameText(): string {
        const text = this.frontPageContent.frontPageFrameText
            .replaceAll('&', '&amp;')
            .replaceAll('<', '&lt;')
            .replaceAll('>', '&gt;')
            .replaceAll('"', '&quot;')
            .replaceAll("'", '&#039;');
        const linkRegex = /\[([^\]]+)\]\(([^)]+)\)/g;
        return text.replace(linkRegex, (_match, displayText, link) => {
            return `<a href="${link}">${displayText}</a>`;
        });
    }

    public get weeklySignMediaSource(): MultiSourceVideo | null {
        return this.weeklySign
            ? {
                  ngtMediaReferenceId: this.weeklySign.ngtMediaReferenceId,
                  cmsVideoReferenceId: null,
                  urlVideoSource: null,
              }
            : null;
    }
}
