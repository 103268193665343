
import { Component, Prop, Vue } from 'vue-property-decorator';
import marked from 'marked';
import ContentPageData from '@/repository/data/ContentPageData';
import GlobalState from '@/state/GlobalState';
import MultiSourceVideoRenderer from '@/components/parts/MultiSourceVideoRenderer.vue';
import SubPages from '@/components/parts/content/SubPages.vue';
import PageMediaHeader from '@/components/parts/PageMediaHeader.vue';

@Component({
    components: {
        PageMediaHeader,
        SubPages,
        MultiSourceVideoRenderer,
    },
})
export default class Page extends Vue {
    @Prop({ type: Object }) public readonly page!: ContentPageData;
    @Prop({ type: Object }) public readonly globalState!: GlobalState;

    public parse(markdown: string) {
        let html = '';
        let arr = marked(markdown).split('\n');
        if (arr.length > 1) {
            html += arr[0];
            for (let i = 1; i < arr.length; i++) {
                if (arr[i - 1].endsWith('>')) {
                    html += '\n' + arr[i];
                } else {
                    html += '<br />' + arr[i];
                }
            }
        } else {
            if (arr.length <= 1) {
                return html;
            } else {
                return arr[0];
            }
        }
        return html;
    }

    public get hasHeaderMedia(): boolean {
        return (
            this.page.cmsVideoReferenceId !== null ||
            this.page.ngtMediaReferenceId !== null ||
            this.page.urlVideoSource !== null
        );
    }
}
