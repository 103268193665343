import NgcApi from '@/repository/api/NgcApi';
import { Observable } from 'rxjs';
import Resource from '@/repository/Resource';
import ProductData from '@/repository/data/ProductData';
import networkBoundResource from '@/repository/networkBoundResource';
import unwrapApiWrapper from '@/repository/unwrapApiWrapper';
import ProductCategoryData from '@/repository/data/ProductCategoryData';
import OrderData from '@/repository/data/OrderData';
import OrderResponse from '@/repository/data/OrderResponse';
import Pageable from '@/repository/data/Pageable';
import Page from '@/repository/Page';
import ShopHeaderData from '@/repository/data/ShopHeader';

export default class WebShopRepository {
    private readonly api: NgcApi;

    public constructor(api: NgcApi) {
        this.api = api;
    }

    public getHeader(language: string): Observable<Resource<ShopHeaderData>> {
        return networkBoundResource(unwrapApiWrapper(this.api.getShopHeader(language)));
    }

    public getProduct(language: string, productId: number): Observable<Resource<ProductData>> {
        return networkBoundResource(unwrapApiWrapper(this.api.getProductById(language, productId)));
    }

    public findProductPage(
        language: string,
        pageable: Pageable,
        categoryFilter: number | null,
    ): Observable<Resource<Page<ProductData>>> {
        const observable = this.api.getProducts(language, pageable, categoryFilter);
        return networkBoundResource(unwrapApiWrapper(observable));
    }

    public findOrganisationProductPage(
        language: string,
        pageable: Pageable,
        categoryFilter: number | null,
    ): Observable<Resource<Page<ProductData>>> {
        const observable = this.api.getOrganisationProducts(language, pageable, categoryFilter);
        return networkBoundResource(unwrapApiWrapper(observable));
    }

    public getProductWithSubscriptionId(subscriptionId: number): Observable<Resource<ProductData | null>> {
        const observable = this.api.getProductWithSubscriptionId(subscriptionId);
        return networkBoundResource(unwrapApiWrapper(observable));
    }

    public getCategories(language: string): Observable<Resource<ProductCategoryData[]>> {
        return networkBoundResource(unwrapApiWrapper(this.api.getProductCategories(language)));
    }

    public getRecommendedProducts(language: string, productId: number): Observable<Resource<ProductData[]>> {
        return networkBoundResource(unwrapApiWrapper(this.api.getRecommendedProducts(language, productId)));
    }

    public postOrder(language: string, order: OrderData): Observable<Resource<OrderResponse>> {
        return networkBoundResource(unwrapApiWrapper(this.api.postProductOrder(language, order)));
    }

    public getDiscountAmount(language: string, order: OrderData, coupon: string): Observable<Resource<number>> {
        return networkBoundResource(unwrapApiWrapper(this.api.getCouponAmount(language, order, coupon)));
    }
}
