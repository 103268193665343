import NgcApi from '@/repository/api/NgcApi';
import Pageable from '@/repository/data/Pageable';
import { Observable } from 'rxjs';
import Page from '@/repository/Page';
import Resource from '@/repository/Resource';
import Publication from '@/repository/data/Publication';
import networkBoundResource from '@/repository/networkBoundResource';
import unwrapApiWrapper from '@/repository/unwrapApiWrapper';

export default class PublicationsRepository {
    private readonly api: NgcApi;

    public constructor(api: NgcApi) {
        this.api = api;
    }

    public findPublicationPage(
        language: string,
        pageable: Pageable,
        query: string | null,
    ): Observable<Resource<Page<Publication>>> {
        const observable = this.api.getPublicationsPage(language, pageable, query);
        return networkBoundResource(unwrapApiWrapper(observable));
    }
}
