import WebShopRepository from '@/repository/WebShopRepository';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import GlobalState from '@/state/GlobalState';
import Resource, { ResourceStatus } from '@/repository/Resource';
import { BasketEntry } from '@/repository/data/BasketEntry';
import { map, switchMap, tap } from 'rxjs/operators';
import ProductData from '@/repository/data/ProductData';

class CartSavable {
    public products: {
        productId: number;
        amount: number;
    }[];
    public discounts: {
        coupon: string;
        amount: number;
    }[];

    constructor(products: [number, number][], discounts: [string, number][]) {
        this.products = products.map((entry) => {
            return { productId: entry[0], amount: entry[1] };
        });

        this.discounts = discounts.map((entry) => {
            return { coupon: entry[0], amount: entry[1] };
        });
    }
}

function createErrorProduct(id: number): ProductData {
    return {
        id,
        price: 0,
        onSale: false,
        digital: false,
        stock: 0,
        title: '',
        description: '',
        organisationOnly: false,
        backOrder: false,
        images: [],
    };
}

export default class ShoppingCart {
    private readonly cart: Map<number, number>;
    private readonly discounts: Map<string, number>;
    private readonly productsCache: ProductData[];
    private globalState: GlobalState;
    private cartObservable: BehaviorSubject<Map<number, number>>;
    private webShopRepository: WebShopRepository;

    constructor(globalState: GlobalState, webShopRepository: WebShopRepository) {
        this.cart = new Map<number, number>();
        this.discounts = new Map<string, number>();
        this.productsCache = [];
        this.cartObservable = new BehaviorSubject<Map<number, number>>(new Map<number, number>());
        this.globalState = globalState;
        this.webShopRepository = webShopRepository;
        this.updateObservables();
    }

    public add(productId: number, amount: number): void {
        if (amount <= 0) {
            return;
        }
        this.cart.set(productId, this.cart.has(productId) ? this.cart.get(productId)! + amount : amount);
        this.updateObservables();
        this.updateCookie();
    }

    public addCoupon(coupon: string, amount: number): void {
        this.discounts.set(coupon, amount);
        this.updateCookie();
        this.updateObservables();
    }

    public clearCoupons(): void {
        this.discounts.clear();
        this.updateCookie();
    }

    public get totalDiscount(): number {
        let total = 0;
        for (const coupon of this.discounts.keys()) {
            if (this.discounts.get(coupon)) {
                total += this.discounts.get(coupon)!;
            }
        }
        return total;
    }

    public get coupons(): Map<string, number> {
        return this.discounts;
    }

    public set(productId: number, amount: number): void {
        if (amount <= 0 && this.cart.has(productId)) {
            this.cart.delete(productId);
        } else {
            this.cart.set(productId, amount);
        }
        this.updateObservables();
        this.updateCookie();
    }

    public remove(productId: number) {
        if (this.cart.has(productId)) {
            this.cart.delete(productId);
        }
        this.updateObservables();
        this.updateCookie();
    }

    public getCartObservable(): Observable<Map<number, number>> {
        return this.cartObservable;
    }

    public observeBasket(languageSubject: BehaviorSubject<string>): Observable<Resource<BasketEntry[]>> {
        return combineLatest([
            languageSubject,
            this.globalState.cart.getCartObservable().pipe(
                map((cart) => {
                    const result: [number, number][] = [];
                    for (const key of cart.keys()) {
                        result.push([key, cart.get(key)!]);
                    }
                    return result;
                }),
            ),
        ]).pipe(
            switchMap(([lang, cart]) => {
                return cart.length > 0
                    ? combineLatest(
                          cart.map(([productId, amount]) => {
                              const product = this.productsCache.find((t) => t.id === productId);
                              return combineLatest([
                                  product
                                      ? of(Resource.success(product))
                                      : this.webShopRepository
                                            .getProduct(lang, productId)
                                            .pipe(
                                                map((productResource) =>
                                                    productResource.isError
                                                        ? productResource.map(() => createErrorProduct(productId))
                                                        : productResource,
                                                ),
                                            ),
                                  of(amount),
                              ]);
                          }),
                      )
                    : of([]);
            }),
            tap((entries) => {
                entries
                    .filter(([productResource]) => productResource.isSuccess)
                    .map(([productResource]) => productResource.data!)
                    .filter((product) => !this.productsCache.some((t) => t.id === product.id))
                    .forEach((product) => this.productsCache.push(product));
            }),
            map((entries) => {
                const data = entries
                    .filter(([productResource]) => !productResource.isLoading)
                    .map(
                        ([productResource, amount]): BasketEntry => ({
                            ...productResource.data!,
                            amount,
                            success: productResource.isSuccess,
                        }),
                    );
                const status = entries.some(([productResource]) => productResource.isLoading)
                    ? ResourceStatus.LOADING
                    : ResourceStatus.SUCCESS;
                return new Resource(status, data, null);
            }),
        );
    }

    private updateObservables() {
        this.cartObservable.next(this.cart);
        this.globalState.setCartSize(this.cart.size);
    }

    public clear() {
        this.cart.clear();
        this.discounts.clear();
        this.updateCookie();
    }

    private updateCookie() {
        window.localStorage.setItem('cart', JSON.stringify(this.toSavableObject()));
    }

    public toSavableObject() {
        const products: [number, number][] = [];
        this.cart.forEach((value, key) => products.push([key, value]));
        const coupons: [string, number][] = [];
        this.discounts.forEach((value, key) => coupons.push([key, value]));
        return new CartSavable(products, coupons);
    }

    public fromSavableObject(input: string) {
        if (!input || !input.includes('{')) {
            return;
        } else {
            const savable: CartSavable = JSON.parse(input);
            for (const discount of savable.discounts) {
                this.discounts.set(discount.coupon, discount.amount);
            }
            for (const product of savable.products) {
                this.cart.set(product.productId, product.amount);
            }
        }

        this.updateObservables();
    }

    public loadFromStorage() {
        const cart = window.localStorage.getItem('cart');
        if (cart) {
            this.fromSavableObject(cart);
        }
    }
}
