
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Violation } from '@/repository/data/ValidationErrorResponse';

@Component
export default class ShopCheckoutSelectField extends Vue {
    @Prop({ type: String, default: '' })
    public readonly value!: string;

    @Prop({ required: true, type: String })
    public readonly name!: string;

    @Prop({ required: false, type: String, default: null })
    public readonly label!: string | null;

    @Prop({ required: false, type: Array, default: () => [] })
    public readonly options!: any[];

    @Prop({ required: false, type: String, default: 'id' })
    public readonly valueKey!: string;

    @Prop({ required: false, type: String, default: 'label' })
    public readonly labelKey!: string;

    @Prop({ required: false, type: Boolean, default: false })
    public readonly horizontal!: boolean;

    @Prop({ required: false, type: Boolean, default: false })
    public readonly optional!: boolean;

    @Prop({ required: true, type: Object })
    public readonly validationErrors!: { [key: string]: Violation };

    public get inputId(): string {
        return 'shop-checkout-' + this.name;
    }

    public get classes(): any {
        return {
            'is-horizontal': this.horizontal,
            required: !this.optional,
        };
    }

    public onInput(event: any): void {
        this.$emit('input', event.target.value || '', event);
    }
}
