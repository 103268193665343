import Vue from 'vue';
import Router, { NavigationGuard } from 'vue-router';
import { Component } from 'vue-property-decorator';
import Home from '@/components/screens/Home.vue';
import Page from '@/components/screens/ContentPage.vue';
import ShopHome from '@/components/screens/shop/Home.vue';
import Product from '@/components/screens/shop/Product.vue';
import Basket from '@/components/screens/shop/Basket.vue';
import Checkout from '@/components/screens/shop/Checkout.vue';
import Publications from '@/components/screens/Publications.vue';
import Completed from '@/components/screens/shop/Completed.vue';
import LoginScreen from '@/components/screens/LoginScreen.vue';
import AccessibleProductsScreen from '@/components/screens/AccessibleProductsScreen.vue';
import Employees from '@/components/screens/Employees.vue';
import { authManager } from '@/container';

Component.registerHooks(['beforeRouteEnter', 'beforeRouteLeave', 'beforeRouteUpdate']);

Vue.use(Router);

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home,
    },
    {
        path: '/login',
        name: 'login',
        component: LoginScreen,
    },
    {
        path: '/publicaties',
        name: 'publications',
        component: Publications,
    },
    {
        path: '/medewerkers',
        name: 'employees',
        component: Employees,
    },
    {
        path: '/shop',
        name: 'shop',
        component: ShopHome,
    },
    {
        path: '/shop/basket',
        name: 'basket',
        component: Basket,
    },
    {
        path: '/shop/checkout',
        name: 'checkout',
        component: Checkout,
    },
    {
        path: '/shop/completed/:order',
        name: 'shopCompleted',
        component: Completed,
    },
    {
        path: '/shop/product/:product',
        name: 'product',
        component: Product,
    },
    {
        path: '/products',
        name: 'accessibleProducts',
        component: AccessibleProductsScreen,
    },
    {
        path: '/:page',
        name: 'page',
        component: Page,
    },
];

let verifiedSessionAlive = false;

const authGuard: NavigationGuard = (to, from, next) => {
    if ((!authManager.token || authManager.expired) && !verifiedSessionAlive && to.name !== 'login') {
        authManager.isSessionAlive().subscribe((resource) => {
            if (!resource.isLoading) {
                verifiedSessionAlive = true;
                if (resource.isSuccess && resource.data) {
                    window.localStorage.setItem(
                        'rememberedRoute',
                        JSON.stringify({ name: to.name, params: to.params, query: to.query }),
                    );
                    authManager.startRedirectLogin();
                } else {
                    next();
                }
            }
        });
    } else {
        next();
    }
};

const router = new Router({
    mode: 'history',
    routes,
});

router.beforeEach(authGuard);

export default router;
