
import { Component, Provide, Vue } from 'vue-property-decorator';
import NavigationMenu from '@/components/parts/NavigationMenu.vue';
import AppFooter from '@/components/parts/AppFooter.vue';
import {
    globalState,
    employeeRepository,
    navigationItemsRepository,
    pageRepository,
    publicationsRepository,
    webShopRepository,
    authManager,
} from '@/container';
import { Subscription } from 'rxjs';
import { BeforeDestroyAware, CreatedAware } from '@/util/lifecycle';

@Component({
    components: { NavigationMenu, AppFooter },
})
export default class App extends Vue implements CreatedAware, BeforeDestroyAware {
    @Provide() public navigationItemsRepository = navigationItemsRepository;
    @Provide() public pageRepository = pageRepository;
    @Provide() public webShopRepository = webShopRepository;
    @Provide() public publicationsRepository = publicationsRepository;
    @Provide() public employeeRepository = employeeRepository;
    @Provide() public globalState = globalState;
    @Provide() public authManager = authManager;

    private languageSubscription = new Subscription();

    public created(): void {
        this.languageSubscription = this.globalState.languageObservable.subscribe((language) => {
            this.$i18n.locale = language;
        });

        this.globalState.cart.loadFromStorage();
    }

    public beforeDestroy(): void {
        this.languageSubscription.unsubscribe();
    }
}
