import { render, staticRenderFns } from "./SubPages.vue?vue&type=template&id=6e3709a2&"
import script from "./SubPages.vue?vue&type=script&lang=ts&"
export * from "./SubPages.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports