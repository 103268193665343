
import { Component, Inject, Vue, Watch } from 'vue-property-decorator';
import NavigationItemsRepository from '@/repository/NavigationItemsRepository';
import NavigationItemData from '@/repository/data/NavigationItem';
import GlobalState from '@/state/GlobalState';
import { switchMap } from 'rxjs/operators';
import { BehaviorSubject, Subscription } from 'rxjs';
import NavigationItemRenderer from '@/components/parts/nav/NavigationItemRenderer.vue';
import User from '@/repository/data/User';

interface LanguageOption {
    short: string;
    long: string;
}

@Component({
    components: { NavigationItemRenderer },
})
export default class NavigationMenu extends Vue {
    public user: User | null = null;
    public userLoading = true;
    public cartSize = new BehaviorSubject(0);
    public readonly languages: { [key: string]: LanguageOption } = {
        'nl-NL': { short: 'NL', long: 'Nederlands' },
        'en-US': { short: 'EN', long: 'English' },
    };
    public navigationItems: NavigationItemData[] = [];

    private menuOverlapping = false;
    private menuExpanded = false;
    private menuAnimationComplete = false;
    private contentSubscription = new Subscription();
    private userSubscription = new Subscription();
    private cartSizeSubscription = new Subscription();
    @Inject() private readonly navigationItemsRepository!: NavigationItemsRepository;
    @Inject() private readonly globalState!: GlobalState;

    public get menuClasses(): { [key: string]: boolean } {
        return {
            'with-background': this.menuOverlapping,
            'is-expanded': this.menuExpanded,
        };
    }

    public created(): void {
        this.contentSubscription = this.globalState.languageObservable
            .pipe(switchMap((language) => this.navigationItemsRepository.getNavigationItems(language)))
            .subscribe((navigationItemsData) => {
                if (navigationItemsData.isSuccess && navigationItemsData.data !== null) {
                    this.navigationItems = navigationItemsData.data;
                }
            });
        this.cartSizeSubscription = this.globalState.cartSizeObservable.subscribe(this.cartSize);
        if (this.$route.name !== 'login') {
            this.globalState.refreshUser();
        }
        this.userSubscription = this.globalState.userObservable.subscribe((userResource) => {
            this.userLoading = userResource.isLoading;
            if (userResource.isSuccess) {
                this.user = userResource.data;
            }
        });
    }

    toggleMenu() {
        this.menuAnimationComplete = false;
        this.menuExpanded = !this.menuExpanded;
        if (this.menuExpanded) {
            this.menuAnimationComplete = true;
        } else {
            setTimeout(() => {
                this.menuAnimationComplete = true;
            }, 300);
        }
    }

    updateScroll() {
        const page = document.getElementById('page');
        if (page) {
            const pageY = page.getBoundingClientRect().top;
            this.menuOverlapping = pageY < 0;
        }
    }

    changeLanguage(val: string) {
        this.globalState.setLanguage(val);
    }

    mounted(): void {
        let mainContent = document.getElementById('main-content');
        if (mainContent) {
            mainContent.addEventListener('scroll', this.updateScroll);
            this.updateScroll();
        }
    }

    public beforeDestroy(): void {
        this.userSubscription.unsubscribe();
        this.cartSizeSubscription.unsubscribe();
        this.contentSubscription.unsubscribe();
    }

    public onLogin(): void {
        this.globalState.beginLogin();
    }

    @Watch('$route')
    public onRouteChanged(): void {
        const main = document.getElementById('main-content');
        if (main) {
            main.scrollTo({ top: 0 });
            this.menuOverlapping = false;
        }
        this.menuExpanded = false;
    }

    @Watch('menuExpanded')
    public onMenuExpandedChanged(expanded: boolean): void {
        if (!expanded) {
            const siteNav: Element | null = this.$refs.siteNav as Element;
            if (siteNav) {
                siteNav.scrollTo({ top: 0 });
            }
        }
    }
}
