
import { Component, Model, Vue, Watch } from 'vue-property-decorator';

@Component
export default class CouponField extends Vue {
    public values!: string[];
    @Model('input', { type: Array, default: [] }) private model!: string[];

    public created(): void {
        this.values = this.model;
        if (this.values.length === 0) {
            this.addField();
        }
    }

    public updateValue(): void {
        this.$emit(
            'input',
            this.values.filter((value) => {
                return value && value !== '';
            }),
        );
    }

    @Watch('model')
    public watcher() {
        if (this.values[this.values.length - 1] && this.values[this.values.length - 1] !== '') {
            this.addField();
        } else if (this.values.length >= 2 && this.values[this.values.length - 2] === '') {
            this.values.pop();
        }
    }

    public addField() {
        this.values.push('');
        this.$forceUpdate();
    }
}
