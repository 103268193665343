import NgcApi from '@/repository/api/NgcApi';
import { Observable } from 'rxjs';
import Resource from '@/repository/Resource';
import EmployeeData from '@/repository/data/EmployeeData';
import unwrapApiWrapper from '@/repository/unwrapApiWrapper';
import networkBoundResource from '@/repository/networkBoundResource';

export default class EmployeeRepository {
    private readonly api: NgcApi;

    public constructor(api: NgcApi) {
        this.api = api;
    }

    public getEmployees(language: string): Observable<Resource<Array<EmployeeData>>> {
        const observable = this.api.getEmployeePage(language);
        return networkBoundResource(unwrapApiWrapper(observable));
    }
}
