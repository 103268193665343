
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import GlobalState from '@/state/GlobalState';
import PinnedPageData from '@/repository/data/PinnedPageData';

@Component
export default class SubPages extends Vue {
    @Prop({ required: true, type: Array })
    private pinnedPages!: PinnedPageData[];

    @Inject() private readonly globalState!: GlobalState;
}
