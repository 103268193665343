import NgcApi from '@/repository/api/NgcApi';
import User from '@/repository/data/User';
import Resource from '@/repository/Resource';
import { Observable } from 'rxjs';
import networkBoundResource from '@/repository/networkBoundResource';
import unwrapApiWrapper from '@/repository/unwrapApiWrapper';

export default class UserRepository {
    private readonly api: NgcApi;

    public constructor(api: NgcApi) {
        this.api = api;
    }

    public findCurrent(): Observable<Resource<User>> {
        const observable = this.api.getCurrentUser();
        return networkBoundResource(unwrapApiWrapper(observable));
    }
}
