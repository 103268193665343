
import { Component, Inject, Vue, Watch } from 'vue-property-decorator';
import PageRepository from '@/repository/PageRepository';
import ContentPageData from '@/repository/data/ContentPageData';
import { BehaviorSubject, Subscription } from 'rxjs';
import GlobalState from '@/state/GlobalState';
import { map, switchMap, tap } from 'rxjs/operators';
import Page from '@/components/parts/content/Page.vue';
import Error from '@/components/parts/content/Error.vue';
import LoadingSpinner from '@/components/parts/LoadingSpinner.vue';

@Component({
    components: { LoadingSpinner, Page, Error },
})
export default class ContentPage extends Vue {
    @Inject() private readonly pageRepository!: PageRepository;
    @Inject() private readonly globalState!: GlobalState;
    private languageSubscription = new Subscription();
    private contentSubscription = new Subscription();
    private availableTranslations: any = {};
    public page?: ContentPageData | null;
    public error?: any | null;
    private loading = true;
    private currentSlug = new BehaviorSubject('');

    public created() {
        this.page = null;
        this.languageSubscription = this.globalState.languageObservable
            .pipe(
                map((language) =>
                    this.availableTranslations.hasOwnProperty(language)
                        ? this.availableTranslations[language]
                        : this.currentSlug.value,
                ),
                tap((slug) => {
                    if (this.$router.currentRoute.path !== '/' + slug) {
                        this.$router.push(slug);
                    }
                }),
            )
            .subscribe(this.currentSlug);
        this.contentSubscription = this.currentSlug
            .pipe(switchMap((slug) => this.pageRepository.getContentPage(slug)))
            .subscribe((pageResource) => {
                this.loading = pageResource.isLoading;
                if (pageResource.isSuccess && pageResource.data !== null) {
                    this.page = pageResource.data;
                    this.availableTranslations = this.page.availableTranslations;
                } else if (pageResource.isError) {
                    this.error = pageResource.error.response;
                }
            });
    }

    @Watch('$route', { immediate: true })
    onUrlChange(to: any, from: any) {
        if (from === undefined || to.path !== from.path) {
            this.currentSlug.next(this.$route.params.page);
        }
    }

    public beforeDestroy(): void {
        this.languageSubscription.unsubscribe();
        this.contentSubscription.unsubscribe();
    }
}
