
import { Component, Inject, Vue, Watch } from 'vue-property-decorator';
import GlobalState from '@/state/GlobalState';
import ProductData from '@/repository/data/ProductData';
import WebShopRepository from '@/repository/WebShopRepository';
import { BehaviorSubject, Subscription } from 'rxjs';
import NumberFieldWithDropdown from '@/components/parts/input/NumberFieldWithDropdown.vue';
import CouponField from '@/components/parts/input/CouponField.vue';
import OrderData from '@/repository/data/OrderData';
import { BeforeDestroyAware, CreatedAware } from '@/util/lifecycle';
import { BasketEntry } from '@/repository/data/BasketEntry';

@Component({
    components: { CouponField, NumberFieldWithDropdown },
    filters: {
        EUR(value: number): string {
            return `${Math.floor(value / 100)},${value % 100 < 10 ? '0' + (value % 100) : value % 100}`;
        },
    },
})
export default class Basket extends Vue implements CreatedAware, BeforeDestroyAware {
    @Inject() globalState!: GlobalState;
    @Inject() private readonly webShopRepository!: WebShopRepository;
    private loading = true;
    private language = new BehaviorSubject('');
    public basket: BasketEntry[] = [];
    private coupons: string[] = [];
    private discount: number = 0;
    private discountSubscription = new Subscription();
    private languageSubscription = new Subscription();
    private cartSubscription = new Subscription();
    private invalidCoupons: string[] = [];
    private newCoupon: string = '';

    public created() {
        this.languageSubscription = this.globalState.languageObservable.subscribe(this.language);
        this.cartSubscription = this.globalState.cart.observeBasket(this.language).subscribe((basketResource) => {
            if (basketResource.isSuccess) {
                this.basket = basketResource.data!;
            }
            this.loading = basketResource.isLoading;
        });

        for (let coupon of this.globalState.cart.coupons.keys()) {
            this.coupons.push(coupon);
        }
    }

    private checkDiscount(coupon: string): void {
        let orderData: OrderData = {
            name: '',
            phone: '',
            email: '',
            surname: '',
            street: '',
            streetNo: '',
            streetNoAdd: '',
            postCode: '',
            city: '',
            deliveryCity: '',
            deliveryStreet: '',
            deliveryStreetNo: '',
            deliveryStreetNoAdd: '',
            deliveryPostCode: '',
            productOrders: [],
            couponCodes: [],
            organisation: null,
            paymentMethod: 'IDEAL',
        };
        orderData.productOrders = [];
        for (let entry of this.basket) {
            orderData.productOrders.push({ productId: entry.id, amount: entry.amount });
        }

        this.webShopRepository.getDiscountAmount(this.language.value, orderData, coupon).subscribe((resource) => {
            if (resource.isSuccess) {
                if (resource.data) {
                    if (resource.data <= 0) {
                        this.invalidCoupons.push(coupon);
                    } else {
                        this.discount += resource.data;
                        this.globalState.cart.addCoupon(coupon, resource.data);
                    }
                } else {
                    this.invalidCoupons.push(coupon);
                }
            }
        });
    }

    public beforeDestroy(): void {
        this.languageSubscription.unsubscribe();
        this.cartSubscription.unsubscribe();
        this.discountSubscription.unsubscribe();
    }

    public get shippingCosts(): number {
        for (let entry of this.basket) {
            if (entry.organisationOnly && !entry.digital) {
                return 795;
            }
        }
        return 0;
    }

    public get totalPrice(): number {
        let totalPrice = 0;
        for (let entry of this.basket) {
            totalPrice += entry.amount * entry.price;
        }
        return Math.max(totalPrice + this.shippingCosts - this.discount, 0);
    }

    public removeItem(id: number) {
        this.globalState.cart.remove(id);
    }

    public updateBasketEntryAmount(entry: BasketEntry, amount: number) {
        this.globalState.cart.set(entry.id, amount);
    }

    public amountCapForProduct(product: ProductData): number {
        if (product.backOrder || (product.digital && product.organisationOnly)) {
            return 99;
        }
        return product.stock;
    }

    @Watch('products', { deep: true })
    private applyCoupons() {
        this.discount = 0;
        let checked: string[] = [];
        this.globalState.cart.clearCoupons();
        for (let coupon of this.coupons) {
            if (checked.includes(coupon)) continue;
            this.checkDiscount(coupon);
            checked.push(coupon);
        }
        this.coupons = this.coupons.filter((t) => !this.invalidCoupons.includes(t));
    }

    public addCoupon() {
        if (!this.coupons.includes(this.newCoupon)) {
            this.coupons.push(this.newCoupon);
            this.newCoupon = '';
            this.applyCoupons();
        }
    }

    public removeCoupon(coupon: string) {
        if (this.coupons.includes(coupon)) {
            this.coupons = this.coupons.filter((t) => t !== coupon);
            this.applyCoupons();
        }
    }

    private isValid(coupon: string) {
        return !this.invalidCoupons.includes(coupon);
    }

    private get canContinue() {
        return this.basket.length > 0 && !this.basket.some((t) => !t.success);
    }

    private get cannotContinueReason() {
        let reason = this.$t('shop.error.cartEmpty');
        if (this.basket.length > 0) {
            if (this.basket.some((t) => !t.success)) {
                reason = this.$t('shop.error.unknownProduct');
            }
        }
        return reason;
    }
}
