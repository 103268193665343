
import { Component, Inject, Vue } from 'vue-property-decorator';
import LoadingSpinner from '@/components/parts/LoadingSpinner.vue';
import { Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import GlobalState from '@/state/GlobalState';
import Pagination from '@/components/parts/Pagination.vue';
import EmployeeRepository from '@/repository/EmployeeRepository';
import EmployeeData from '@/repository/data/EmployeeData';
import MultiSourceVideoRenderer from '@/components/parts/MultiSourceVideoRenderer.vue';
import MultiSourceVideo from '@/repository/data/MultiSourceVideo';
import PageMediaHeader from '@/components/parts/PageMediaHeader.vue';

@Component({
    components: {
        PageMediaHeader,
        MultiSourceVideoRenderer,
        LoadingSpinner,
        Pagination,
    },
})
export default class Employees extends Vue {
    public loading = true;
    public employees: EmployeeData[] = [];

    @Inject() private globalState!: GlobalState;
    @Inject() private employeeRepository!: EmployeeRepository;
    private selected: number = 0;
    private languageSubscription = new Subscription();
    private error: string | null = null;

    public created(): void {
        this.languageSubscription = this.globalState.languageObservable
            .pipe(switchMap((language) => this.employeeRepository.getEmployees(language)))
            .subscribe((pageResource) => {
                this.loading = pageResource.isLoading;
                if (pageResource.isSuccess && pageResource.data !== null) {
                    this.employees = pageResource.data;
                } else if (pageResource.isError) {
                    this.error = pageResource.error.response;
                }
            });
    }

    public beforeDestroy(): void {
        this.languageSubscription.unsubscribe();
    }

    public get multiSourceVideo(): MultiSourceVideo | null {
        return this.employees[this.selected];
    }

    setSelected(index: number) {
        this.selected = index;
    }

    public get title(): string | null {
        if (this.employees[this.selected]) {
            return this.employees[this.selected].title;
        } else {
            return null;
        }
    }

    public get subtitle(): string | null {
        if (this.employees[this.selected]) {
            return this.employees[this.selected].subtitle;
        } else {
            return null;
        }
    }

    public get name(): string | null {
        if (this.employees[this.selected]) {
            return this.employees[this.selected].name;
        } else {
            return null;
        }
    }
}
