import NgcApi from '@/repository/api/NgcApi';
import NavigationItem from '@/repository/data/NavigationItem';
import { Observable } from 'rxjs';
import Resource from '@/repository/Resource';
import networkBoundResource from '@/repository/networkBoundResource';
import unwrapApiWrapper from '@/repository/unwrapApiWrapper';

export default class NavigationItemsRepository {
    private readonly api: NgcApi;

    constructor(api: NgcApi) {
        this.api = api;
    }

    public getNavigationItems(language: string): Observable<Resource<Array<NavigationItem>>> {
        return networkBoundResource(unwrapApiWrapper(this.api.getNavigationItems(language)));
    }
}
