var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"page-content",attrs:{"id":"page"}},[_c('div',{staticClass:"top-margin background"}),_c('div',{staticClass:"page-media-header"},[_c('div',{staticClass:"background"}),_c('div',{staticClass:"container"},[_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-full is-two-thirds-desktop text"},[_c('div',{staticClass:"title"},[_c('h1',{domProps:{"innerHTML":_vm._s(_vm.$t('publications.title'))}})]),_c('label',{staticClass:"subheader",attrs:{"for":"search-publications"}},[_vm._v(" "+_vm._s(_vm.$t('publications.header'))+" ")])])])])]),_c('main',{staticClass:"container publications"},[_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-full is-two-thirds-desktop"},[_c('div',{staticClass:"field"},[_c('div',{staticClass:"control has-icons-right"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],staticClass:"input",attrs:{"id":"search-publications","role":"search","placeholder":_vm.$t('publications.inputs.search.placeholder')},domProps:{"value":(_vm.search)},on:{"input":function($event){if($event.target.composing)return;_vm.search=$event.target.value}}}),_vm._m(0)])])])]),(!_vm.loading)?_c('div',{staticClass:"columns is-multiline result-list"},_vm._l((_vm.publications),function(entry){return _c(entry.mediaUrl?.includes('vimeo') ? 'div' : 'a',{key:entry.id,tag:"component",class:{
                    'no-file': !entry.fileId && !entry.mediaUrl,
                    'result-item': entry.mediaUrl?.includes('vimeo'),
                },attrs:{"href":(entry.fileId ? _vm.globalState.getFileUrl(entry.fileId) : entry.mediaUrl) ?? '#',"aria-label":entry.subject}},[_c('div',{staticClass:"column is-full is-two-thirds-desktop"},[_c('h2',[_vm._v(_vm._s(entry.subject))]),_c('p',{staticClass:"description"},[_vm._v(_vm._s(entry.authors))]),(entry.mediaUrl?.includes('vimeo'))?_c('div',{staticClass:"library-video"},[_c('MultiSourceVideoRenderer',{attrs:{"data-source":{
                                urlVideoSource: entry.mediaUrl,
                                ngtMediaReferenceId: null,
                                cmsVideoReferenceId: null,
                            }}})],1):_vm._e(),_c('p',{staticClass:"publication"},[_vm._v(_vm._s(entry.year)+" - "+_vm._s(entry.source))])])])}),1):_c('div',[_c('LoadingSpinner')],1),_c('div',{staticClass:"gap"}),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('Pagination',{attrs:{"total-page-count":_vm.totalPages,"current-page":_vm.currentPageIndex},on:{"pageSelected":_vm.onPageSelected}})],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('span',{staticClass:"icon is-right"},[_c('i',{staticClass:"ow-ic ow-ic-magnify"})])
}]

export { render, staticRenderFns }