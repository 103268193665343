
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Observable, Subscription } from 'rxjs';

@Component
export default class Carousel extends Vue {
    @Prop({ type: Number, default: 0 }) private readonly startingImage!: number;
    @Prop({ type: Object }) private readonly imageObservable!: Observable<[]>;
    @Prop({ type: Number, default: 0 }) private readonly autoSlideInterval!: number;
    @Prop({ type: Boolean, default: false }) private readonly showProgressBar!: boolean;

    private imagesSubscription = new Subscription();
    private images: { id: number; big: string; thumb: string }[] = [];
    //Index of the active image
    private activeImage: number = 0;
    //Hold the timeout, so we can clear it when it is needed
    private autoSlideTimeout: any = null;
    //If the timer is stopped e.g. when hovering over the carousel
    private stopSlider: boolean = false;
    //Hold the time left until changing to the next image
    private timeLeft: number = 0;
    //Hold the interval so we can clear it when needed
    private timerInterval: any = null;
    //Every 10ms decrease the timeLeft
    private countdownInterval: number = 10;

    public created() {
        //Check if startingImage prop was given and if the index is inside the images array bounds
        if (this.startingImage && this.startingImage >= 0 && this.startingImage < this.images.length) {
            this.activeImage = this.startingImage;
        }
        //Check if autoSlideInterval prop was given and if it is a positive number
        if (this.autoSlideInterval && this.autoSlideInterval > this.countdownInterval) {
            //Start the timer to go to the next image
            this.startTimer(this.autoSlideInterval);
            this.timeLeft = this.autoSlideInterval;
            //Start countdown to show the progressbar
            this.startCountdown();
        }

        this.imagesSubscription = this.imageObservable.subscribe((images) => {
            this.images = images;
        });
    }

    public beforeDelete() {
        this.imagesSubscription.unsubscribe();
    }

    // currentImage gets called whenever activeImage changes
    // and is the reason why we don't have to worry about the
    // big image getting updated
    get currentImage(): string | null {
        this.timeLeft = this.autoSlideInterval;
        if (this.activeImage >= 0 && this.activeImage < this.images.length) {
            return this.images[this.activeImage].big;
        }
        return null;
    }

    get progressBar() {
        //Calculate the width of the progressbar
        return 100 - (this.timeLeft / this.autoSlideInterval) * 100;
    }

    // Go forward on the images array
    // or go at the first image if you can't go forward
    public nextImage(): void {
        let active = this.activeImage + 1;
        if (active >= this.images.length) {
            active = 0;
        }
        this.activateImage(active);
    }
    // Go backwards on the images array
    // or go at the last image
    public prevImage(): void {
        let active = this.activeImage - 1;
        if (active < 0) {
            active = this.images.length - 1;
        }
        this.activateImage(active);
    }

    public activateImage(imageIndex: number): void {
        this.activeImage = imageIndex;
    }

    //Wait until 'interval' and go to the next image;
    public startTimer(interval: number): void {
        if (interval && interval > 0 && !this.stopSlider) {
            let self = this;
            clearTimeout(this.autoSlideTimeout);
            this.autoSlideTimeout = setTimeout(function () {
                self.nextImage();
                self.startTimer(self.autoSlideInterval);
            }, interval);
        }
    }

    //Stop the timer when hovering over the carousel
    public stopTimer(): void {
        clearTimeout(this.autoSlideTimeout);
        this.stopSlider = true;
        clearInterval(this.timerInterval);
    }

    //Restart the timer(with 'timeLeft') when leaving from the carousel
    public restartTimer(): void {
        this.stopSlider = false;
        clearInterval(this.timerInterval);
        this.startCountdown();
        this.startTimer(this.timeLeft);
    }

    //Start countdown from 'autoSlideInterval' to 0
    public startCountdown(): void {
        if (!this.showProgressBar) return;
        let self = this;
        this.timerInterval = setInterval(function () {
            self.timeLeft -= self.countdownInterval;
            if (self.timeLeft <= 0) {
                self.timeLeft = self.autoSlideInterval;
            }
        }, this.countdownInterval);
    }
}
