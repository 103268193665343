export enum SortDirection {
    ASC = 'ASC',
    DESC = 'DESC',
}

export interface Sort {
    property: string;
    direction: SortDirection;
}

export default interface Pageable {
    page: number;
    size: number;
    sort: Sort[];
    filter?: any;
}
