
import { Component, Inject, Vue } from 'vue-property-decorator';
import GlobalState from '@/state/GlobalState';
import WebShopRepository from '@/repository/WebShopRepository';
import { BehaviorSubject, Subscription } from 'rxjs';
import OrderData from '@/repository/data/OrderData';
import User from '@/repository/data/User';
import { BasketEntry } from '@/repository/data/BasketEntry';
import ValidationErrorResponse, { Violation } from '@/repository/data/ValidationErrorResponse';
import ShopCheckoutField from '@/components/parts/shop/ShopCheckoutField.vue';
import ShopCheckoutSelectField from '@/components/parts/shop/ShopCheckoutSelectField.vue';
import Organization from '@/repository/data/Organization';
import {switchMap} from "rxjs/operators";

@Component({
    components: {
        ShopCheckoutField,
        ShopCheckoutSelectField,
    },
    filters: {
        EUR(value: number): string {
            return `${Math.floor(value / 100)},${value % 100 < 10 ? '0' + (value % 100) : value % 100}`;
        },
    },
})
export default class Checkout extends Vue {
    @Inject() globalState!: GlobalState;
    @Inject() private readonly webShopRepository!: WebShopRepository;
    public differentDeliveryAddress = false;
    public acceptedTerms = false;
    public termsFileId: number | null = null;
    public loading = true;
    private language = new BehaviorSubject('');
    public basket: BasketEntry[] = [];
    private termsSubscription = new Subscription();
    private productSubscriptions: Subscription[] = [];
    private languageSubscription = new Subscription();
    private cartSubscription = new Subscription();
    private responseSubscription = new Subscription();
    private userSubscription = new Subscription();
    private renewalSubscription = new Subscription();
    private user: User | null = null;
    private processingOrder = false;
    public hasSwitchedTabs = false;
    public validationErrors: { [key: string]: Violation } = {};
    public orderErrors: any[] = [];
    public orderData: OrderData = {
        city: '',
        deliveryCity: '',
        deliveryPostCode: '',
        deliveryStreet: '',
        deliveryStreetNo: '',
        deliveryStreetNoAdd: null,
        name: '',
        phone: null,
        postCode: '',
        productOrders: [],
        street: '',
        streetNo: '',
        streetNoAdd: null,
        surname: '',
        email: '',
        couponCodes: [],
        organisation: null,
        paymentMethod: 'IDEAL',
    };

    public created() {
        if (this.$route.query.hasOwnProperty('subscriptionRenewId')) {
            let subscriptionId: string = this.$route.query.subscriptionRenewId as string;
            this.renewalSubscription = this.webShopRepository
                .getProductWithSubscriptionId(parseInt(subscriptionId))
                .subscribe((product) => {
                    if (product.isSuccess && product.data) {
                        this.globalState.cart.set(product.data.id, 1);
                    }
                });
        }

        let jsonOrder = window.localStorage.getItem('orderData');
        if (jsonOrder) {
            this.orderData = JSON.parse(jsonOrder);
            window.localStorage.removeItem('orderData');
        }
        this.userSubscription = this.globalState.userObservable.subscribe((userResource) => {
            if (userResource.isSuccess && userResource.data) {
                this.user = userResource.data;
                if (this.orderData.organisation == null && this.shopperOrganisations.length > 0) {
                    this.orderData.organisation = this.shopperOrganisations[0].id;
                    this.orderData.paymentMethod = 'BILL_ORG';
                }
                this.validateOrder();
            }
        });
        this.languageSubscription = this.globalState.languageObservable.subscribe(this.language);
        this.cartSubscription = this.globalState.cart.observeBasket(this.language).subscribe((basketResource) => {
            if (basketResource.isSuccess) {
                this.basket = basketResource.data!;
            }
            this.loading = basketResource.isLoading;
            this.validateOrder();
        });
        this.termsSubscription = this.language
            .pipe(switchMap((language) => this.webShopRepository.getHeader(language)))
            .subscribe((resource) => {
                if (resource.isSuccess && resource.data) {
                    this.termsFileId = resource.data.termsFileId;
                }
            });
        this.loading = false;
    }

    private clearProductSubscriptions(): void {
        for (let subscription of this.productSubscriptions) {
            subscription.unsubscribe();
        }
        this.productSubscriptions = [];
    }

    public beforeDestroy(): void {
        this.clearProductSubscriptions();
        this.languageSubscription.unsubscribe();
        this.termsSubscription.unsubscribe();
        this.cartSubscription.unsubscribe();
        this.responseSubscription.unsubscribe();
        this.userSubscription.unsubscribe();
        this.renewalSubscription.unsubscribe();
    }

    public openTab(tabName: string): void {
        this.validateFields();
        this.hasSwitchedTabs = tabName === 'payment' && !this.hasFieldErrors;
        this.validateOrder();
    }

    public get shippingCosts(): number {
        for (let entry of this.basket) {
            if (entry.organisationOnly && !entry.digital) {
                return 795;
            }
        }
        return 0;
    }

    public get totalPrice(): number {
        let totalPrice = 0;
        for (let entry of this.basket) {
            totalPrice += entry.amount * entry.price;
        }
        return Math.max(totalPrice + this.shippingCosts - this.globalState.cart.totalDiscount, 0);
    }

    public get shopperOrganisations(): Organization[] {
        if (this.user) {
            return this.user.organizations.filter((t) => t.shopper);
        } else {
            return [];
        }
    }

    public get selectedOrganisationName(): string {
        const organisation = this.shopperOrganisations.find((t) => t.id === this.orderData.organisation);
        return organisation ? organisation.name : '';
    }

    public onSubmit(): void {
        this.validateFields();
        this.validateOrder();
        if (!this.acceptedTerms || this.processingOrder || this.hasFieldErrors || this.orderErrors.length > 0) {
            return;
        }
        this.processingOrder = true;

        if (!this.differentDeliveryAddress) {
            this.orderData.deliveryCity = this.orderData.city;
            this.orderData.deliveryPostCode = this.orderData.postCode;
            this.orderData.deliveryStreet = this.orderData.street;
            this.orderData.deliveryStreetNo = this.orderData.streetNo;
            this.orderData.deliveryStreetNoAdd = this.orderData.streetNoAdd;
        }

        this.orderData.couponCodes = [];
        for (let code of this.globalState.cart.coupons.keys()) {
            this.orderData.couponCodes.push(code);
        }

        this.orderData.productOrders = [];
        for (let entry of this.basket) {
            this.orderData.productOrders.push({ productId: entry.id, amount: entry.amount });
        }

        this.webShopRepository.postOrder(this.language.value, this.orderData).subscribe((resource) => {
            if (resource.isSuccess) {
                this.globalState.cart.clear();
                if (resource.data) {
                    window.open(resource.data.mollieUrl, '_self');
                }
            } else if (resource.isError) {
                const error = resource.error;
                if (
                    error.response &&
                    error.response.status === 400 &&
                    error.response.data &&
                    error.response.data.data
                ) {
                    const data = error.response.data.data;
                    if (data.orderFailed) {
                        const product = this.basket.find((t) => t.id === data.productId);
                        const productName = product ? product.title : '';
                        this.orderErrors = [];
                        this.orderErrors.push(this.$t('shop.error.' + data.code, { name: productName }));
                    } else if (data.validationError) {
                        const validationErrorResponse: ValidationErrorResponse = data;
                        this.validationErrors = {};
                        validationErrorResponse.violations.forEach((t) => (this.validationErrors[t.field] = t));
                        this.hasSwitchedTabs = false;
                        this.orderErrors.push(this.$t('shop.error.invalidFields'));
                    } else {
                        this.orderErrors.push(this.$t('shop.error.failed'));
                    }
                } else {
                    this.orderErrors.push(this.$t('shop.error.failed'));
                }
            }
            this.processingOrder = resource.isLoading;
        });
    }

    public validateFields(): void {
        const fields = ['name', 'surname', 'street', 'streetNo', 'city', 'postCode', 'email', 'phone'];
        const optFields = ['deliveryCity', 'deliveryPostCode', 'deliveryStreet', 'deliveryStreetNo'];
        this.validationErrors = {};
        fields.concat(optFields).forEach((field) => {
            if (!(field in this.validationErrors)) {
                if (!optFields.includes(field) || this.differentDeliveryAddress) {
                    if (field in this.orderData) {
                        const value: string | null = (this.orderData as any)[field];
                        if (value === null || value.trim().length === 0) {
                            this.validationErrors[field] = {
                                field,
                                rule: 'NotEmpty',
                                value: null,
                            };
                        }
                    }
                }
            }
        });
        this.validateOrganisation();
    }

    public validateOrder(): void {
        this.orderErrors = [];
        if (this.hasFieldErrors) {
            this.orderErrors.push(this.$t('shop.error.invalidFields'));
        }
        if (this.basket.length === 0) {
            this.orderErrors.push(this.$t('shop.error.cartEmpty'));
        }
        for (let entry of this.basket) {
            if (entry.digital) {
                if (!entry.organisationOnly && entry.amount != 1) {
                    this.orderErrors.push(this.$t('shop.error.maxOneDigital'));
                }
            }
            if (entry.organisationOnly) {
                if (!this.isShopper) {
                    this.orderErrors.push(this.$t('shop.error.requiresOrg'));
                }
            }
        }
    }

    public validateOrganisation(): void {
        if (this.requiresShopper && this.shopperOrganisations.length > 1) {
            if (this.orderData.organisation === null || this.orderData.organisation === undefined) {
                this.validationErrors['organisation'] = {
                    field: 'organisation',
                    rule: 'NotEmpty',
                    value: null,
                };
            }
        }
    }

    public get requireLogin(): boolean {
        return (
            !this.user &&
            (this.basket.some((t) => t.digital) ||
                ('email' in this.validationErrors && this.validationErrors.email.rule === 'EmailNotInUse'))
        );
    }

    public get requiresShopper(): boolean {
        for (let entry of this.basket) {
            if (entry.organisationOnly) {
                return true;
            }
        }
        return false;
    }

    public get isShopper(): boolean {
        if (this.user) {
            for (let organisation of this.user.organizations) {
                if (organisation.shopper) {
                    return true;
                }
            }
        }
        return false;
    }

    public get hasFieldErrors(): boolean {
        return Object.keys(this.validationErrors).length > 0;
    }

    public get canCheckOut() {
        return this.acceptedTerms && !this.processingOrder && !this.hasFieldErrors && this.orderErrors.length === 0;
    }

    public login() {
        window.localStorage.setItem('loginFromCart', 'true');
        window.localStorage.setItem('orderData', JSON.stringify(this.orderData));
        this.globalState.beginLogin();
    }
}
