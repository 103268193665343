
import { Component, Inject, Vue } from 'vue-property-decorator';
import AccessibleProduct from '@/repository/data/AccessibleProduct';
import LoadingSpinner from '@/components/parts/LoadingSpinner.vue';
import GlobalState from '@/state/GlobalState';
import { Subscription } from 'rxjs';
import { BeforeDestroyAware, CreatedAware } from '@/util/lifecycle';

interface ProductLink extends AccessibleProduct {
    link: string;
    startDate: string;
}

@Component({
    components: {
        LoadingSpinner,
    },
})
export default class AccessibleProductsScreen extends Vue implements CreatedAware, BeforeDestroyAware {
    public loading = true;
    public loggedIn = false;
    public products: ProductLink[] = [];

    @Inject()
    private globalState!: GlobalState;
    private userSubscription = new Subscription();

    public get currentProducts(): ProductLink[] {
        return this.products.filter(({ active }) => active);
    }

    public get futureProducts(): ProductLink[] {
        return this.products.filter(({ active }) => !active);
    }

    public created(): void {
        const linkMap: { [key: string]: (id: number) => string } = {
            SIGN_BOOK_PRIMARY: () => process.env.VUE_APP_PRODUCT_LINK_OW!,
            SIGN_BOOK_MODULE: (id) => process.env.VUE_APP_PRODUCT_LINK_OW! + '/search?l=' + id,
            SIGN_BOOK_CREATOR: () => process.env.VUE_APP_PRODUCT_LINK_MJEGB!,
            EDUCATION_PORTAL: (id) => process.env.VUE_APP_PRODUCT_LINK_ELO! + id,
            SIGN_COURSES: () => process.env.VUE_APP_PRODUCT_LINK_GS!,
        };
        this.userSubscription = this.globalState.userObservable.subscribe((resource) => {
            if (resource.hasData()) {
                const dateTimeFormatter = new Intl.DateTimeFormat();
                this.loggedIn = true;
                this.products = resource.data.products
                    .filter((t) => t.productType in linkMap)
                    .map((t) => ({
                        ...t,
                        link: linkMap[t.productType](t.id) || '#',
                        startDate: dateTimeFormatter.format(new Date(t.start)),
                    }));
            } else {
                this.loggedIn = false;
            }
            this.loading = resource.isLoading;
        });
    }

    public beforeDestroy(): void {
        this.userSubscription.unsubscribe();
    }

    public onLogin(): void {
        this.globalState.beginLogin();
    }

    public onLogout(): void {
        this.globalState.beginLogout();
    }
}
