import NgcApi from '@/repository/api/NgcApi';
import { Observable } from 'rxjs';
import Resource from '@/repository/Resource';
import networkBoundResource from '@/repository/networkBoundResource';
import ContentPageData from '@/repository/data/ContentPageData';
import PinnedPageData from '@/repository/data/PinnedPageData';
import WeeklySignData from '@/repository/data/WeeklySignData';
import unwrapApiWrapper from '@/repository/unwrapApiWrapper';
import DirectNavigationData from '@/repository/data/DirectNavigationData';
import FooterData from '@/repository/data/FooterData';
import FrontPageContent from "@/repository/data/FrontPageContent";

export default class PageRepository {
    private readonly api: NgcApi;

    constructor(api: NgcApi) {
        this.api = api;
    }

    public getContentPage(slug: string): Observable<Resource<ContentPageData>> {
        return networkBoundResource(unwrapApiWrapper(this.api.getContentPage(slug)));
    }

    public getPinnedPages(language: string): Observable<Resource<Array<PinnedPageData>>> {
        return networkBoundResource(unwrapApiWrapper(this.api.getPinnedPages(language)));
    }

    public getWeeklySign(language: string): Observable<Resource<WeeklySignData>> {
        return networkBoundResource(unwrapApiWrapper(this.api.getWeeklySign(language)));
    }

    public getFrontPageContent(language: string): Observable<Resource<FrontPageContent>> {
        return networkBoundResource(unwrapApiWrapper(this.api.getFrontPageContent(language)));
    }

    public getDirectNavigation(language: string): Observable<Resource<Array<DirectNavigationData>>> {
        return networkBoundResource(unwrapApiWrapper(this.api.getDirectNavigation(language)));
    }

    public getFooter(language: string): Observable<Resource<FooterData>> {
        return networkBoundResource(unwrapApiWrapper(this.api.getFooter(language)));
    }
}
