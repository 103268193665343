
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Violation } from '@/repository/data/ValidationErrorResponse';

@Component
export default class ShopCheckoutField extends Vue {
    @Prop({ type: String, default: '' })
    public readonly value!: string;

    @Prop({ required: true, type: String })
    public readonly name!: string;

    @Prop({ required: false, type: String, default: null })
    public readonly label!: string;

    @Prop({ required: false, type: String, default: 'half' })
    public readonly size!: string;

    @Prop({ required: false, type: Boolean, default: false })
    public readonly optional!: boolean;

    @Prop({ required: true, type: Object })
    public readonly validationErrors!: { [key: string]: Violation };

    public get inputId(): string {
        return 'shop-checkout-' + this.name;
    }

    public get classes(): any {
        return {
            'is-half-tablet': this.size === 'half',
            'is-one-third-tablet': this.size === 'one-third',
            'is-one-sixth-tablet': this.size === 'one-sixth',
            required: !this.optional,
        };
    }

    public onInput(event: any): void {
        this.$emit('input', event.target.value || '', event);
    }
}
