import HttpClient from '@/util/http/HttpClient';
import NgcApi from '@/repository/api/NgcApi';
import NavigationItemsRepository from '@/repository/NavigationItemsRepository';
import PageRepository from '@/repository/PageRepository';
import WebShopRepository from '@/repository/WebShopRepository';
import PublicationsRepository from '@/repository/PublicationsRepository';
import GlobalState from '@/state/GlobalState';
import AuthStore from '@/util/auth/AuthStore';
import AuthManager from '@/util/auth/AuthManager';
import UserRepository from '@/repository/UserRepository';
import EmployeeRepository from '@/repository/EmployeeRepository';

const httpClient = new HttpClient({});
const authStore = new AuthStore();
const api = new NgcApi(httpClient);
const userRepository = new UserRepository(api);
export const navigationItemsRepository = new NavigationItemsRepository(api);
export const pageRepository = new PageRepository(api);
export const webShopRepository = new WebShopRepository(api);
export const publicationsRepository = new PublicationsRepository(api);
export const employeeRepository = new EmployeeRepository(api);
export const authManager = new AuthManager(
    process.env.VUE_APP_AUTH_ENDPOINT!,
    process.env.VUE_APP_AUTH_CLIENT_ID!,
    process.env.VUE_APP_AUTH_LOGOUT_URI!,
    httpClient,
    authStore,
);
export const globalState = new GlobalState(authManager, userRepository, webShopRepository);
